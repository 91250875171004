import {authApi} from '@/store/services/auth';
import type {RootState} from '@/store/store';
import type {IUser} from '@/types/user';
import {createSlice, type PayloadAction} from '@reduxjs/toolkit';

export type AuthState = {
  user: IUser | null;
  token: string | null;
};

const initialState = {
  token: null,
  user: null,
} as AuthState;

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    // // Login
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
      },
    );
    // // Signup Verify Otp
    // builder.addMatcher(
    //   authApi.endpoints.verifyOtp.matchFulfilled,
    //   (state, action) => {
    //     state.user = action.payload.user;
    //     state.token = action.payload.token;
    //   },
    // );
  },
});

export const {logout, setAccessToken, setUserDetails} = slice.actions;

export default slice.reducer;

export const selectUserDetails = (state: RootState) => state.auth.user;

export const selectUserToken = (state: RootState) => state.auth.token;
