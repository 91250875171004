import React, {Suspense} from 'react';
import {ActivityIndicator} from 'react-native';

const withLazy = (Comp: any, Loader?: any) => {
  return (props: any) => {
    return (
      <Suspense fallback={<ActivityIndicator size="small" />}>
        <Comp {...props} Loader={Loader} />
      </Suspense>
    );
  };
};

export default withLazy;
