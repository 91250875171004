import {Linking, Platform} from 'react-native';

export const openTelephone = (number: number) => {
  let phoneNumber = '';
  if (Platform.OS === 'ios') {
    phoneNumber = `telprompt:${number}`;
  } else {
    phoneNumber = `tel:${number}`;
  }

  Linking.openURL(phoneNumber).catch(error => console.log('error', error));
};

export const openEmail = (email: string) => {
  Linking.openURL(`mailto:${email}`);
};

export const appendStorageBaseUrl = (url: string) => {
  return `https://api.breakbag.com/storage/${url}`;
};

export const populateDayNights = (days: number, nights: number) => {
  return `${nights} Night${nights > 1 ? 's' : ''} ${days} Day${
    days > 1 ? 's' : ''
  }`;
};

export const populateNights = (nights: number) => {
  return `${nights} Night${nights > 1 ? 's' : ''}`;
};

export const populateHours = (hours: number) => {
  return `${hours} Hour${hours > 1 ? 's' : ''}`;
};

export const formatCurrency = (money?: number | string) => {
  if (!money || (typeof money === 'string' && money.length == 0)) return '';
  const newMoney = typeof money === 'string' ? Number(money) : money;
  return (isNaN(newMoney) ? 0 : newMoney).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    unitDisplay: 'short',
    maximumFractionDigits: Platform.OS === 'android' ? undefined : 0,

    currencyDisplay: 'symbol',
  });
};

export const formatCurrencyWorklet = (money: string) => {
  'worklet';
  const newMoney = Number(money);
  return (isNaN(newMoney) ? 0 : newMoney).toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    unitDisplay: 'short',
    maximumFractionDigits: Platform.OS === 'android' ? undefined : 0,
    currencyDisplay: 'symbol',
  });
};

export const splitEmail = (item: string) => item.split('@')[0];
