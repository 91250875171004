import {type Theme, DefaultTheme} from '@react-navigation/native';

export const Colors = {
  white: '#fff',
  text: '#000',
  lightText: '#00000050',
  primary: '#EC1C24',
  light: '#707070',
  green: '#0fb680',
  red: '#ff1a1a',
};

export const defaultTheme: Theme = {
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.white,
    text: Colors.text,
    primary: Colors.primary,
  },
};
