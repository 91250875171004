import React from 'react';
import {View} from 'react-native';

import HeroComponentLoader from './HeroComponentLoader';

const TALoader = () => {
  return (
    <View className="flex flex-1">
      <HeroComponentLoader onPressBack={() => {}} onPressLogo={() => {}} />
      <View className="flex pt-4 max-w-custom self-stretch custom:w-full custom:self-center">
        <View className="bg-white flex self-stretch gap-y-6 px-[20px] py-[30px] custom:px-0">
          <View className="flex self-stretch gap-2">
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-[40%] rounded-[2px] bg-lt-background" />
          </View>
          <View className="flex self-stretch gap-2">
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-full rounded-[2px] bg-lt-background" />
            <View className="h-6 w-[40%] rounded-[2px] bg-lt-background" />
          </View>
        </View>
      </View>
    </View>
  );
};

export default TALoader;
