import {AppRegistry} from 'react-native';

import App from './App';
// Generate the required CSS
import iconFont from './assets/fonts/icomoon.ttf';

import './global.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-toastify/dist/ReactToastify.css';

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: icomoon;
}`;

// Create a stylesheet
const style = document.createElement('style');
style.type = 'text/css';

// Append the iconFontStyles to the stylesheet
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject the stylesheet into the document head
document.head.appendChild(style);

const appName = 'break_bag';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
