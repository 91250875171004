import React, {type FC} from 'react';
import {Pressable, View, useWindowDimensions} from 'react-native';

type TourDetailsLoaderProps = {};

const CarouselLoader = () => {
  return (
    <View className="flex max-w-custom self-stretch gap-1 custom:w-full custom:self-center flex-row aspect-[2/1.2] md:aspect-[3/1.2]">
      <Pressable className="flex flex-1 bg-white" />
      <View className="flex w-[30%] gap-1 h-full">
        <View className="flex flex-1 bg-white" />
        <View className="flex flex-1 bg-white" />
      </View>
    </View>
  );
};

const TourInfoLoader: FC = () => {
  const {width} = useWindowDimensions();

  const itemSize = (width >= 1080 ? width - 352 : width) - 32;
  const columns = width >= 1080 ? 5 : width >= 700 ? 7 : width >= 570 ? 5 : 4;
  return (
    <View className="flex pb-4 pt-[14px] custom:pt-[24px] max-w-custom self-stretch custom:w-full custom:self-center">
      <View className="flex self-stretch gap-x-[12px] flex-row">
        <View className="flex flex-1 self-stretch gap-4">
          <View className="bg-white flex self-stretch px-[20px] py-[30px]">
            <View className="flex self-stretch flex-row items-center justify-between">
              <View className="bg-lt-background h-[14px] rounded-[2px] w-[50%]" />
            </View>
            <View className="mt-2 rounded-[2px] bg-lt-background h-[18px] lg:h-[22px]" />
            <View className="flex mt-3 self-stretch flex-row items-center justify-between">
              <View className="gap-2">
                <View className="h-[14px] w-[100px] bg-lt-background rounded-[2px]" />
                <View className="h-[22px] w-[100px] bg-lt-background rounded-[2px]" />
              </View>
              {/* <View className="px-[16px] rounded-[8px] bg-primary h-btn-sm justify-center" /> */}
            </View>
          </View>
          <View className="bg-white flex self-stretch gap-y-4 flex-row flex-wrap px-[20px] py-[30px]">
            {[1, 2, 3, 4, 5, 6].map(item => (
              <View
                style={{width: itemSize / columns}}
                key={item}
                className="flex bg-red items-center gap-3">
                <View
                  style={{width: itemSize / columns - 30}}
                  className="aspect-square bg-lt-background"
                />
                <View className="h-[14px] w-[120px] bg-lt-background px-4 rounded-[2px]" />
              </View>
            ))}
          </View>
          <View className="bg-white flex self-stretch gap-y-4 px-[20px] py-[30px]">
            <View className="mb-3 h-[18px] custom:h-[24px] w-[150px] bg-lt-background rounded-[2px]" />
            <View className="flex self-stretch gap-2">
              {[1, 2].map(item => (
                <View
                  key={item}
                  className="flex self-stretch flex-row gap-2 pr-3">
                  <View className="w-[14px] h-[14px] bg-lt-background mt-[2px]" />
                  <View className="h-[14px] w-[160px] rounded-[2px] bg-lt-background" />
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const TourDetailsLoader: FC<TourDetailsLoaderProps> = () => {
  return (
    <View className="self-stretch">
      <CarouselLoader />
      <TourInfoLoader />
    </View>
  );
};

export default TourDetailsLoader;
