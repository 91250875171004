import {
  configureStore,
  type ConfigureStoreOptions,
  type ThunkDispatch,
} from '@reduxjs/toolkit';
import {api} from './services/api';
import {useSelector, type TypedUseSelectorHook} from 'react-redux';
import auth, {type AuthState} from './features/auth/authSlice';
import type {UnknownAction} from '@reduxjs/toolkit/react';

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const dispatch = store.dispatch as ThunkDispatch<
  RootState,
  unknown,
  UnknownAction
>;

export type RootState = {auth: AuthState};

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
