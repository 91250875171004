import React, {type FC} from 'react';
import {
  Bounce,
  ToastContainer as TSTContainer,
  toast,
  type ToastContainerProps,
} from 'react-toastify';
import type {TToastType} from './toast-types';

export const showToast = (message: string, type?: TToastType) => {
  if (!type) {
    toast(message);
    return;
  }

  toast[type](message);
};

const ToastContainer: FC<Pick<ToastContainerProps, 'position'>> = ({
  position = 'top-right',
}) => {
  return (
    <TSTContainer
      position={position}
      autoClose={5000}
      theme="light"
      hideProgressBar={false}
      transition={Bounce}
    />
  );
};

export {ToastContainer};
