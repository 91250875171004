import React, {type FC} from 'react';
import {View} from 'react-native';

import {Colors} from '@/utils/colors';
import TourDetailsHeader from './TourDetails/TourDetailsHeader';

const HeroComponentLoader: FC<{
  onPressLogo: () => void;
  onPressBack: () => void;
}> = props => {
  const {onPressLogo, onPressBack} = props;

  return (
    <View className="self-stretch h-[40vh] md:h-[60vh] custom:h-[70vh] p-[20px] md:p-20 justify-center bg-lt-background">
      <TourDetailsHeader
        onPressBack={onPressBack}
        tintColor={Colors.primary}
        isTop
        className="absolute top-0 left-0 right-0 mb-0 bg-transparent"
        onPressLogo={onPressLogo}
      />
      <View className="flex w-full max-w-custom custom:self-center gap-4">
        <View
          pointerEvents="none"
          className="w-[20px] md:w-[40px] h-[4px] md:h-[6px] rounded-[3px] bg-skeleton mb-2"
        />
        <View className="h-[26px] md:h-[54px] w-[60%] bg-skeleton rounded-[2px]" />
        <View className="h-[16px] md:h-[18px] w-[40%] bg-skeleton rounded-[2px]" />
      </View>
    </View>
  );
};

export default HeroComponentLoader;
