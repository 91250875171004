import React, {type FC} from 'react';
import Text from '@/components/Text';
import {Colors} from '@/utils/colors';
import {
  Menu as RNMenu,
  MenuButton,
  MenuItem,
  MenuDivider,
} from '@szhsin/react-menu';
import Icon, {IconTypes} from '../Icon';

type IMenuItem = {
  name: string;
  id: string;
  icon?: IconTypes;
};

export type IMenuProps = {
  data: IMenuItem[];
  menuTrigger?: React.ReactElement;
  onPressItem: (item: {name: string; id: string; icon?: IconTypes}) => void;
};

const Menu: FC<IMenuProps> = props => {
  const {data, menuTrigger, onPressItem} = props;
  return (
    <RNMenu menuButton={<MenuButton>{menuTrigger}</MenuButton>}>
      {data.map(item =>
        typeof item === 'string' && item === 'line' ? (
          <MenuDivider key={item} />
        ) : (
          <MenuItem
            onClick={() => onPressItem(item)}
            key={item.id}
            className="hover:bg-highlight transition-all">
            {item.icon ? (
              <Icon name={item.icon} size={12} color={Colors.text} />
            ) : null}
            <Text className="text-sans text-black text-sm">{item.name}</Text>
          </MenuItem>
        ),
      )}
    </RNMenu>
  );
};

export default Menu;
