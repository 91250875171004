import {api} from '@/store/services/api';
import type {
  ILoginRequestBody,
  ILoginResponse,
  IResetPasswordByEmailRequestBody,
  ISignupRequestBody,
  ISignupResponse,
  IVerifyOtpRequestBody,
  IVerifyOtpResponse,
} from '@/types/auth';

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<ILoginResponse, ILoginRequestBody>({
      query: credentials => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),

      // extraOptions: {
      //   backoff: () => {
      //     // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
      //     retry.fail({fake: 'error'});
      //   },
      // },
    }),

    loginVerify: build.mutation<ILoginResponse, ILoginRequestBody>({
      query: credentials => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),

      // extraOptions: {
      //   backoff: () => {
      //     // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
      //     retry.fail({fake: 'error'});
      //   },
      // },
    }),

    signUp: build.mutation<ISignupResponse, ISignupRequestBody>({
      query: (credentials: any) => ({
        url: 'auth/otp',
        method: 'POST',
        body: credentials,
      }),
      // extraOptions: {
      //   backoff: () => {
      //     // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
      //     retry.fail({fake: 'error'});
      //   },
      // },
    }),

    verifyOtp: build.mutation<IVerifyOtpResponse, IVerifyOtpRequestBody>({
      query: (credentials: any) => ({
        url: 'auth/signup',
        method: 'POST',
        body: credentials,
      }),
    }),

    resetPassword: build.mutation<
      IVerifyOtpResponse,
      IResetPasswordByEmailRequestBody
    >({
      query: (credentials: any) => ({
        url: 'auth/signup',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useLoginVerifyMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useVerifyOtpMutation,
} = authApi;

export const {
  endpoints: {signUp, login, verifyOtp},
} = authApi;
