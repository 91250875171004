import {type UserCredentials} from 'react-native-keychain';

const key = '$3no2b343?:e3y';

export const setGenericPassword = (username: string, password: string) => {
  localStorage.setItem(key, JSON.stringify({username, password}));
};

export const getGenericPassword = (): Promise<UserCredentials | false> => {
  return new Promise(resolve => {
    const data = localStorage.getItem(key);

    if (data === null) {
      resolve(false);
      return;
    }

    resolve(JSON.parse(data));
  });
};

export const resetGenericPassword = (): Promise<boolean> => {
  return new Promise(resolve => {
    localStorage.removeItem(key);
    resolve(true);
  });
};
