import React, {type FC} from 'react';
import {MenuProvider} from 'react-native-popup-menu';
import {Provider} from 'react-redux';

import {ToastContainer} from './components/Toast/Toast';
import MainNavigator from '@/navigators/MainNavigator';
import {linking} from '@/utils/navigation-options';
import {NavigationContainer} from '@react-navigation/native';

import {defaultTheme} from './utils/colors';
import {store} from './store/store';
import {Platform, View} from 'react-native';
import Toast from 'react-native-toast-message';

const App: FC = () => {
  return (
    <View className="flex flex-1">
      <MenuProvider>
        <Provider store={store}>
          <NavigationContainer theme={defaultTheme} linking={linking}>
            <MainNavigator />
          </NavigationContainer>
        </Provider>
      </MenuProvider>
      <ToastContainer />
      {Platform.OS === 'web' ? <Toast /> : null}
    </View>
  );
};

export default App;
