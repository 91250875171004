import {
  getGenericPassword,
  setGenericPassword,
  resetGenericPassword,
} from 'react-native-keychain';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const getAuthDetails = () => {
  return getGenericPassword();
};

export const setAuthDetails = (userId: string, token: string) => {
  return setGenericPassword(userId, token);
};

export const deleteAuth = () => {
  return resetGenericPassword();
};

type DataKeys = 'user-data';

export const setData = (key: DataKeys, data: any) => {
  return AsyncStorage.setItem(key, JSON.stringify(data));
};

export const getData = async (key: DataKeys) => {
  const item = await AsyncStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const removeData = (key: DataKeys) => {
  return AsyncStorage.removeItem(key);
};
