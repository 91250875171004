import React, {type FC, type PropsWithChildren} from 'react';
import {
  Platform,
  Pressable,
  View,
  Image,
  StyleSheet,
  type StyleProp,
  type ViewStyle,
  type ColorValue,
} from 'react-native';
import Text from '@/components/Text';
import type {AnimatedStyle} from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useSelector} from 'react-redux';

import {selectUserDetails} from '@/store/features/auth/authSlice';
import {cn} from '@/utils/cn';
import {Colors} from '@/utils/colors';
import logo from '../../images/logo.png';
import HeaderProfileMenu from '../HeaderProfileMenu';
import Icon, {IconTypes} from '../Icon';

type TourDetailsHeaderProps = PropsWithChildren & {
  style?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>;
  onPressLogo: () => void;
  isTop?: boolean;
  className?: string;
  tintColor?: ColorValue;
  onPressLogin?: () => void;
  textClassName?: string;
  onPressBack: () => void;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 6,
    backgroundColor: '#fff',
  },
  webContainer: {},
});

const TourDetailsHeader: FC<TourDetailsHeaderProps> = props => {
  const {
    onPressLogo,
    style,
    textClassName,
    tintColor,
    isTop,
    className,
    onPressBack,
    onPressLogin,
  } = props;
  const {top} = useSafeAreaInsets();

  const userDetails = useSelector(selectUserDetails);

  if (Platform.OS === 'web') {
    if (!isTop) {
      return null;
    }

    return (
      <View className={`self-stretch ${className}`}>
        <View className="flex self-stretch backdrop-blur h-[50px] custom:h-[60px]">
          <View className="max-w-custom w-full h-full flex-row items-center custom:self-center py-2 px-[20px] custom:px-0 justify-between">
            <Pressable onPress={onPressLogo}>
              <Image
                style={tintColor ? {tintColor} : undefined}
                source={logo}
                resizeMode="contain"
                className="h-[40px] hover:t aspect-[3/1]"
              />
            </Pressable>
            {!userDetails ? (
              <Pressable
                onPress={onPressLogin}
                className="group h-[80%] justify-center transition-all bg-primary rounded-md px-4 hover:bg-lt-background">
                <Text
                  className={cn(
                    `text-white group-hover:text-primary`,
                    textClassName,
                  )}>
                  Login
                </Text>
              </Pressable>
            ) : (
              <HeaderProfileMenu
                tintColor={(tintColor as string) || '#000'}
                textClassName={
                  tintColor === '#fff' ? 'text-white' : 'text-black'
                }
                userDetails={userDetails}
              />
            )}
          </View>
        </View>
      </View>
    );
  }

  if (isTop) {
    return null;
  }

  return (
    <Animated.View
      style={[
        styles.container,
        {
          height: top + 46,
          paddingTop: top,
        },
        style,
      ]}>
      <Pressable
        onPress={onPressBack}
        className="flex custom:hidden h-[36px] bg-[#ffffff99] rounded-[18px] aspect-square items-center justify-center">
        <Icon name={IconTypes.ChevronLeft} color={Colors.text} size={20} />
      </Pressable>
    </Animated.View>
  );
};

export default TourDetailsHeader;
